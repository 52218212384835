import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import Fetch from '../../pkg/fetch';
import query from './query';
import Table from './table';
import Loader from 'react-loader-spinner'
import {OrderName} from "../helper.js"
import {  Link } from "react-router-dom";

// const Serialize = item => {
// 	return {
// 		ID: item.ReqID,
// 		Status: item.Status,
// 		Number: item.Number,
// 		Response: item.Response,
// 		// CreatedOn: new Date(item.CreatedOn*1000),
// 		CreatedOn: item.CreatedOn,
// 		// UpdatedOn: new Date(item.UpdatedOn*1000),
// 		UpdatedOn: item.UpdatedOn,
// 		Customer: item.Customer,
// 	}
// };

// причём без запятых, а то микрософт от них тошнит
// вот так 05.01.19 10:28
var options = {
  minute: 'numeric',
  hour: 'numeric',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timezone: 'UTC'
};

const getFrom = () => {
	let d = new Date()
	d.setDate(1)
	d.setMonth(d.getMonth()-1)
	d.setHours(0)
	d.setMinutes(0)
	return d
}

const getTo = () => {
	let d = new Date()
	d.setDate(1)
	d.setMonth(d.getMonth())
	d.setHours(0)
	d.setMinutes(0)
	return d
}

class Report extends React.Component {
	constructor(props){
		super(props)

		props.location.search.replace("?","").split("&").forEach(v=>{
			const [name, value]= v.split("=")
			// console.log(name, value)
			switch (name) {
				case "from":
					this.state[name] = new Date(value);
					break;
				case "to":
					this.state[name] = new Date(value);
					break;
				case "Limit":
					this.state[name] = Number(value);
					break;
				default:
				 this.state[name]=value;
			}

		})
		// console.log("state",this.state)
	}

	componentDidMount(){
		this.fetch()
	}
	state = {
		// executer: "unit",
		from: getFrom(),
		to: getTo(),
	}


	fetch = async () => {
		this.setState({fetched: true});
		const {data, errors, status} = await Fetch(query, {...this.state,
			from: Math.ceil(this.state.from.getTime()/1000,0),
			to: Math.ceil(this.state.to.getTime()/1000,0),
		});
		if (!errors) {
			const items = data.items
			await this.setState({
				status,
				...data,
				len: data.items.length,
				items: items,
				fetched: false,
			});
			return
		}
		errors.forEach(err => {
			const {message} = err;
			if (message === "StatusUnauthorized"){
				this.setState({status: 401})
			}
		})
	}

  serialize(item){
    return{
      ...item,
      CreatedOn: new Date(item.CreatedOn*1000).toLocaleString("ru", options).replace(",", ""),
      UpdatedOn: new Date(item.UpdatedOn*1000).toLocaleString("ru", options).replace(",", ""),
    }
  }
	render(){
    // console.log(this.state.items)
		let resp = ""
		if (this.state.items){
			this.state.items.map(this.serialize).forEach((v,i)=>{
				let head="N;"
				let row=(i + 1 )+";"

				for (let [key, value] of Object.entries(v)) {
						head = head + key + ";"
						row = row + value + ";"
				}
				if (i===0) {
					resp = resp + head + "\n"
				}
				resp = resp + row+ "\n"
			})
		}

		return (
			<pre dangerouslySetInnerHTML={{ __html: resp }} />
		);
	}
}


export default Report;
