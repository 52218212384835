import React from 'react';
import Modal from '../modal';
import DateTimePicker from 'react-datetime-picker';

class Statuses extends React.Component {
  state={Colapse:false}
  handle = e => {
    e.preventDefault()
    this.setState({Colapse:!this.state.Colapse})
  }
  onChange = name => value =>{
    this.props.handle({
      target:{name,value, type: 'mydate'},
      preventDefault: ()=> {},
    })
  }

  render(){
    const props = this.props;
    const propsButton={
      type:'button',
      onClick: this.handle,
      className: "btn btn-primary",
    }
    // console.log(props)
    // const items = ['*', ...props.items]
    return !this.state.Colapse
      ? <button {...propsButton} >Filter</button>
      :(
      <Modal close={this.handle} done={props.handleFetch}>
      <form onSubmit={e => e.preventDefault()}>
        <div className="form-group">
          <label>From month</label>
          <div className="input-group mb-3">
            <DateTimePicker
              onChange={this.onChange('from')}
              value={props.state.from}
            />
            <DateTimePicker
              onChange={this.onChange('to')}
              value={props.state.to}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Customer</label>
          <input name="Customer" className="form-control"  placeholder="Customer"
            onKeyUp={props.handle} defaultValue={props.Customer}/>
        </div>

        <div className="form-group">
          <label>Status</label>
          <input name="Status" className="form-control"  placeholder="Status"
            onKeyUp={props.handle} defaultValue={props.Status}/>
        </div>
        <div className="form-group">
          <label>Row Limit</label>
          <input name="Limit" className="form-control"
            type="number"
            placeholder="Limit"
            onKeyUp={props.handle} defaultValue={props.state.Limit}/>
        </div>
      </form></Modal>)
  };
}
export default Statuses;
