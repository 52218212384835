export default `
  query($Hash:String!){
    block: blockByHash(Hash:$Hash) {
      ReqID
      Customer
      Hash
      Chain
      State
      Retry
      Lock
      Log {
        Message
        Stamp
      }
      SC {
        External {
          ID
          Status
          Number
          Response
        }
        User {
          Address {
            PostalCode
            City
            Street
            Street2
          }
          ContactName
          Email
          Name
          Phone
        }
        Supplier {
          Group
          Name
          Received
          Reply
          Response
        }
        CI {
          Category
          Model
          Name
          Status
          Sticker
        }
        Chain
        Description
        Impact
        Information
        OperationCategory
        ReqOrganization
        ReqPriority
        ReqSolution
        ReqStatus
        ReqStatusExternal
        Response
        ServiceName
        ReqId
      }
    }
  }
`;
