import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import sha256 from 'js-sha256';

// import PropTypes from 'prop-types';
// import Table from './table';
// import Fetch from '../../pkg/fetch';
// import query from './query';
// import { Store } from '../../pkg/store/';

const dd = d => (d > 8) ? `${d + 1}` : `0${d + 1}`;

class Login extends Component {

	state = {
		status: false,
		username : "",
		// username : "admin",
		password : "",
		// password : "Aij3ba9i",
	}

	handleSubmit = e => {
		e.preventDefault()
		const {username, password } = this.state;
		if (!username){
			return;
		}
		const body = JSON.stringify({username, password: sha256(password)});
		const options = {
		  method: 'POST',
		  headers: {
		    Accept: 'application/json',
		    'Content-Type': 'application/json',
		  },
		  body,
		};
		fetch('/signin', options)
		.then(resp => {
			this.setState({status: resp.status})
		})
	}

	handleLogout = e => {
		e.preventDefault()
		document.cookie = 'session_token=; Max-Age=0'
	}

	handleChange = e => {
		e.preventDefault()
		const {name, value} = e.target;
		if (!value) return;
		const state = {};
		state[name] = value;
		this.setState(state);
	}


	render = () => {
		// console.log(this.state);
		const {handleChange, handleSubmit, handleLogout} = this;
		const d = new Date();

		// const to = `/requests/${d.getFullYear()}/${dd(d.getMonth())}`;
		const to = `/letual`;

		return (this.state.status === 200)
		? (<Redirect to={to} />)

		:(<div>
			<div className="col-xs-12" style={{height:50}}></div>
			<div className="jumbotron offset-md-3 col-6">
			<form onSubmit={handleSubmit}>
				<div className="text-center">
					<Link className="navbar-brand" to="/">
				    	<img src="https://servicechain.ru/assets/img/logo_hover.svg" />&nbsp;
				    <span>SERVICECHAIN</span>
				  </Link>
			  </div>
			  <hr/>
			  <div className="form-group">
			    <label >Username</label>
			    <input
			    	onChange={handleChange} name="username" defaultValue={this.state.username}
			    	type="text" className="form-control" placeholder="Enter username"/>
			    <small  className="form-text text-muted">We'll never share your username with anyone else.</small>
			  </div>
			  <div className="form-group">
			    <label>Password</label>
			    <input onChange={handleChange} type="password" name="password" defaultValue={this.state.username}
			    	type="password" className="form-control"  placeholder="Password"/>
			  </div>
			  <hr/>
			  <div className="btn-group" role="group">
				  <button type="submit" className="btn btn-primary">Login</button>
				  <button type="button" className="btn btn-secondary">Logout</button>
			  </div>
			</form>
		</div></div>
		);
	}
}

export default Login;
