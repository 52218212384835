import React, { Component } from 'react';
// import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashRouter as Router, Route } from "react-router-dom";
import Layout from './pkg/layout/';
import { Store, State } from './pkg/store/';

import Main from './route/main/';
import Request from './route/request';
import Block from './route/block';
import Hash from './route/hash';
import Login from './route/login';

import Report from './route/report';
import Report1 from './route/report1';
import Requests from './route/requests';
import Letual from './route/letual';
import Errors from './route/errors';
import ReqErrors from './route/request_errors';
import Verny from './route/verny';
import Unit from './route/unit';
import Helpline from './route/helpline';
import Mapper from './route/map';

class App extends Component {
  render() {
    return (
      <Router>
        <Store.Provider value={State}>
          <Layout>
            <Route path="/" exact component={Login} />
            <Route path="/401" exact component={Login} />
            <Route path="/report" exact component={Report} />
            <Route path="/csv" exact component={Report1} />
            <Route path="/mapper" exact component={Mapper} />
            <Route path="/errors" exact component={Errors} />
            <Route path="/request_errors" exact component={ReqErrors} />

            <Route path="/letual" exact component={Letual} />
            <Route path="/letual/:year" exact component={Letual} />
            <Route path="/letual/:year/:month" exact component={Letual} />

            <Route path="/requests" exact component={Requests} />

            <Route path="/verny" exact component={Verny} />
            <Route path="/verny/:year/:month" exact component={Verny} />
            <Route path="/verny/:reqid" exact component={Verny} />

            <Route path="/unit" exact component={Unit} />
            <Route path="/unit/:year/:month" exact component={Unit} />
            <Route path="/unit/:reqid" exact component={Unit} />

            <Route path="/helpline" exact component={Helpline} />
            <Route path="/helpline/:year/:month" exact component={Helpline} />
            <Route path="/helpline/:reqid" exact component={Helpline} />

            <Route path="/Request/:reqid" exact component={Request} />
            <Route path="/block/:id" exact component={Block} />
            <Route path="/hash/:hash" exact component={Hash} />
          </Layout>
        </Store.Provider>
      </Router>
    );
  }
}

export default App;
