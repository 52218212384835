import 'whatwg-fetch';

const browserGraphql = async (query, variables) => {
  const body = JSON.stringify({ query, variables });
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  };
  const resp = await fetch('/graphql', options);
  if (resp.status !== 200){
    return {status: resp.status}
  }
  return resp.json();
};

export default browserGraphql;