
export default `query($Order: String, $Limit: Int){
  CustomerErrorPosts:Requests(ErrC:true Limit: $Limit, Order:"-_id") {
    ReqID
    ReqNumber
    Customer
    Executer
    Status
    Response
    Number
    CreatedOn
    UpdatedOn
    ErrC
    ErrE
  }
  ExecuterErrorPosts:Requests(ErrE:true, Limit: $Limit, Order:$Order) {
    ReqID
    ReqNumber
    Customer
    Executer
    Status
    Response
    Number
    CreatedOn
    UpdatedOn
    ErrC
    ErrE
  }
  ExecuterIDErrorPosts:Requests(ExecuterID:"", Limit: $Limit, Order:$Order) {
    ReqID
    ReqNumber
    Customer
    Executer
    Status
    Response
    Number
    CreatedOn
    UpdatedOn
    ErrC
    ErrE
  }

}`;


