
export const UpdateQuery = `
  mutation(
    $ID: String!,
    $State: Int!,
    $Retry: Int!,
    $Lock: Boolean!
  ){
    UpdateBlock(
      ID:$ID,
      State: $State,
      Retry:$Retry,
      Lock: $Lock
    )
  }
`;

export default `
  query($ID:String!){
    block(ID:$ID) {
      ReqID
      Customer
      Hash
      Chain
      State
      Retry
      Lock
      Log {
        Message
        Stamp
      }
      SC {
        Files {
          Source
          Result
          Filename
          Hash
          CreatedOn
          UpdatedOn
        }
        ReqDeadline
        ReqDeadlineTime
        External {
          ID
          Status
          Number
          Response
          Origin
          Files {
            Source
            Result
            Filename
            Hash
            CreatedOn
            UpdatedOn
          }
        }
        User {
          Address {
            PostalCode
            City
            Street
            Street2
          }
          ContactName
          Email
          Name
          Phone
        }
        Supplier {
          Group
          Name
          Received
          Reply
          Response
        }
        CI {
          Category
          Model
          Name
          Status
          Sticker
        }
        Chain
        Description
        Impact
        Information
        OperationCategory
        ReqOrganization
        ReqPriority
        ReqSolution
        ReqStatus
        ReqOrigin
        ReqStatusExternal
        Response
        ServiceName
        ReqId
        ReqNumber
      }
    }
  }
`;


