import React from 'react';
const Layout = props => {
	return <div className="container-fluid">
		{props.children}
	</div>;
};

Layout.propTypes = {};

export default Layout;
