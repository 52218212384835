import React from 'react';
import Loader from 'react-loader-spinner'

const statuses = [
  ["","Все", ""],
  ["Unknown","Пусто", "danger"],
  ["Adopt","Принят", 'success'],
  ["Done", "Выполнено", "info"],
  ["Cancel","Отклонено", 'warning'],

  // ["Reg","Зарегистрирована", ''],
  // ["CloseRemote","Закрыта удалённо"],
  // ["Worked","Устройство работает"],

  // ["Close","Закрыта"],
]

const Panel = (props)=>{

	return( !props.fetched
	? <div className="form-group"><div className="btn-group" role="group" aria-label="Basic example">
		{statuses.map(v=>{
			return (<button
				key={`${props.name}${v[0]}`}
				onClick={props.handle && props.handle(v[1])}
				className={`btn btn-${v[2]}`}>
				{v[1]}: {props[props.name + v[0]]}
			</button>)
		})}
	</div></div>
	: <Loader
	 	type="Watch"
	 	color="#00BFFF"
	 	height={20}
	 	width={20}
	/>)
}

export default Panel;

