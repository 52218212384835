export const Remove = `mutation($Customer:String!, $ReqID:String!)
{
  Remove(Customer:$Customer, ReqID:$ReqID )
}`;

export default `query($ReqID:String!){
  items: blocks(ReqID:$ReqID) {
    ID
    Income{From}
    ReqID
    Customer
    Hash
    CreatedOn
    Retry
    State
    SC {
      External {
        ID
        Status
        Number
        Response
      }
    }
  }
}`;


