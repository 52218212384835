import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import Table from './table';
// import Fetch from '../../pkg/fetch';
// import query from './query';
import { Store } from '../../pkg/store/';

class Main extends Component {
	render() {
		return (
			<Store.Consumer>
				{ ({auth}) => (<form className="col-lg-6" onSubmit={auth.onSubmit}>
					<h5>SC</h5>
				  <button type="submit" className="btn btn-primary">Auth</button>
				</form>)}
			</Store.Consumer>
		);
	}
}

export default Main;
