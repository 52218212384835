import React from 'react';
import {  Link } from "react-router-dom";
// import PropTypes from 'prop-types';
const m = new Date().getMonth() + 1;
const mon =  m > 9 ? m : `0${m}`;

const year = new Date().getFullYear();

const URLs = [
  {name:"Requests", to:"/requests"},
  {name:"Letual", to:"/letual"},
  {name:"Verny", to:`/verny`},
  {name:"Unit", to:`/Unit`},
  {name:"Helpline", to:`/helpline`},
  {name:"Report", to:`/report`},
  {name:"Errors", to:`/errors`},
  {name:"ReqErrs", to:`/request_errors`},
]

const Component = props => {
  // console.log({props})
  const links =  URLs.map(v=>({...v,
    cls: props.location.pathname === v.to ? "active" : "",
  }))
  // console.log({links})
  return (<nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#e3f2fd", margin:"8px 0"}}>
  <Link className="navbar-brand" to="/">
    <img src="https://servicechain.ru/assets/img/logo_hover.svg" />&nbsp;
    <span>SERVICECHAIN</span>
  </Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      {links.map(v=>{
        return (<li key={v.name} className={`nav-item ${v.cls}`}>
          <Link className="nav-link" to={v.to}>{v.name}</Link>
        </li>)
      })}
    </ul>
  </div>
</nav>);
};

Component.propTypes = {
};

export default Component;
