import React from 'react';
// import PropTypes from 'prop-types';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const Sorter = ({ name, val }) => {
	if ("-" + name === val) return <FaCaretDown />;
	if ( name === val) return <FaCaretUp />;
	return <span />
};


export default Sorter;
