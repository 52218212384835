import React from 'react';
// import PropTypes from 'prop-types';
import {  Link } from "react-router-dom";
import Sorter from '../../pkg/sorter/'

const style = {
   whiteSpace: 'nowrap',
   textAlign:'center',
   verticalAlign: 'middle',
};
const style1 = {
  ...style,
  width: "12em",
};

const options = {
  // era: 'long',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  // weekday: 'long',
  // timezone: 'UTC',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};

const Thead = props =>(<thead>
    <tr>
      <th style={style} onClick={props.handle("ID")}>
        Customer's<br/> system ID <Sorter name="ID" val={props.order} />
        </th>
      <th style={style} onClick={props.handle("ReqNumber")}>
        ReqNumber<Sorter name="ReqNumber" val={props.order} />
        </th>
      <th style={style} onClick={props.handle("Customer")}>
        Customer <Sorter name="Customer" val={props.order} />
        </th>
       <th style={style} onClick={props.handle("Executer")}>
        Executer <Sorter name="Executer" val={props.order} />
        </th>
      <th style={style} onClick={props.handle("Status")}>
        Status <Sorter name="Status" val={props.order} />
        </th>
      <th style={style} onClick={props.handle("Number")}>
        Contractor's<br/> system ID <Sorter name="Number" val={props.order} />
        </th>
      <th style={style} onClick={props.handle("Response")}>
        Comment <Sorter name="Response" val={props.order} />
        </th>
       <th style={style1} onClick={props.handle("CreatedOn")}>
        Created on <Sorter name="CreatedOn" val={props.order} />
        </th>
       <th style={style1} onClick={props.handle("UpdatedOn")}>
        Updated on <Sorter name="UpdatedOn" val={props.order} />
        </th>
    </tr>
</thead>);

const Status = sc => {
  // console.log(sc);

  if (!sc.Number) {
    return "table-danger"
  }
  if (sc.Status === 'Выполнено') {
    return "table-success"
  }
  if (sc.Status === 'Отклонено') {
    return "table-warning"
  }

}

// const reqNum = props => (
//   props.ReqID == props.ReqNumber
//   ? <Link to={`/request/${props.ReqID}`}> {props.ReqID}</Link>
//   : <Link to={`/request/${props.ReqID}`}> {props.ReqID}<br/>{props.ReqNumber}</Link>
//   )

const Row = (props)=>{
  return (<tr key={`${props.ReqID}`} className={Status(props)}>
    <td><Link to={`/request/${props.ReqID}`}> {props.ReqID}</Link></td>
    <td>{props.ReqNumber}</td>
    <td>{props.Customer}</td>
    <td>{props.Executer}</td>
    <td>{props.Status}</td>
    <td>{props.Number}</td>
    <td>{props.Response}</td>
    <td>
      {new Date(props.CreatedOn * 1000).toLocaleString("ru", options)}
    </td>
    <td>
      {new Date(props.UpdatedOn * 1000).toLocaleString("ru", options)}
    </td>
  </tr>)
};

const  Table = props => {
    return (<table className="table table-striped table-bordered">
      <Thead order={props.order} handle={props.handle}/>
      <tbody>
        {props.items && props.items.map(Row)}
      </tbody>
    </table>);
  }


export default Table;
