import React, { Component } from 'react';
import * as d3 from "d3";

const data = {
  nodes: [
    {id:'letual', r:40, c: "red"},
    {"id":'postbox', r:50, c: "green"},
    {"id":'unit', r: 40,c: "blue"},
    {"id":'verny', r: 40,c: "black"},
    {"id":'helpline', r: 40,c: "black"},
  ],
  "links": [
    {"source": "letual", "target": "postbox"},
    {"source": "unit", "target": "postbox"},
    {"source": "verny", "target": "postbox"},
    {"source": "helpline", "target": "postbox"},
  ]
}

class Mapper extends Component {
  scaleColor = d3.scaleSequential(d3.interpolateViridis);
  scaleHeight = d3.scaleLinear();
  scaleWidth = d3.scaleBand().padding(0.1);
  componentDidMount() {
        this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  updateChart() {
    const { width=480, height=480 } = this.props;
    const svg = d3.select(this.viz)

    const simulation = d3.forceSimulation()
      .force("charge", d3.forceManyBody().strength(-500))
      .force("link", d3.forceLink().id( d => (d.id)).distance(40))
      .force("x", d3.forceX(width / 2))
      .force("y", d3.forceY(height / 2))
      .on("tick", ticked);

    let node = svg.selectAll(".node");
    let link = svg.selectAll(".link")

    simulation.nodes(data.nodes);
    // simulation.force("link").links(data.links);
    link = link
      .data(data.links)
      .enter().append("line")
      .attr("stroke", "black" )
      .attr("class", "link");

    node = node
      .data(data.nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      // .attr("transform", function(d){return "translate("+d.x+",80)"})

    node.append("circle")
      .attr("r", d=>(d.r))
      .style("stroke", d=>(d.c) )
      .style("fill", "white" )
      //

    node
      .append("text")
      .attr("dx", d=>((d.r/-2)) )
      .text(d => d.id )

    function ticked() {
      node.attr("transform", d => {
        return `translate(${d.x},${d.y})`
      })

      link.attr("x1", d => (data.nodes.find(v=>(v.id===d.source)).x))
          .attr("y1", d => (data.nodes.find(v=>(v.id===d.source)).y))
          .attr("x2", d => (data.nodes.find(v=>(v.id===d.target)).x))
          .attr("y2", d => (data.nodes.find(v=>(v.id===d.target)).y));
    }
  }



  render() {
    const { width=480, height=480 } = this.props;
    return (
      <svg ref={ viz => (this.viz = viz) }
         width={width} height={height} />

    );
  }
}

export default Mapper;
