import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {OrderName} from "../helper.js"
// import PropTypes from 'prop-types';
//
import { Store } from '../../pkg/store/';
import Head from '../../pkg/head/';
import Table from './table';
import Fetch from '../../pkg/fetch';
import query from './query';
import Loader from 'react-loader-spinner'
import Filter from '../../pkg/filter';
import Panel from '../../pkg/panel';
// import DonutChart from '../../pkg/donut/';
// import PieChart from 'react-minimal-pie-chart';

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vars:{
        Order: '-UpdatedOn',
        Limit: 100,
      },
      fetched: true,
      order: 'ReqID',
      items: []
    };
    setInterval(()=>{
      console.log("Data refresh.");
      this.fetch()
    }, 5 * 60 * 1000)
  }
  componentDidMount() {
    this.fetch();
    }

  handelFilter = async e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      this.fetch();
      return
    }
    const {name, value, type} = e.target;
    const state = {};
    switch(type){
      case "mydate":
        state[name]= new Date(value);
        break;
      case "number":
        state[name]= Number(value);
        break;
      default:
        state[name]=value
    }
    // console.log(state)

    await this.setState({vars:{...this.state.vars, ...state}});
  }

  fetch = async () => {
    this.setState({fetched:true});
    const vars =  {
      ...this.state.vars,
    }
    const {data, errors, status} = await Fetch(query, vars);
    if (!errors) {
      const idx = [];
      this.setState({
        status,
        ...data,
        items: [
          ...data.CustomerErrorPosts,
          ...data.ExecuterErrorPosts,
          ...data.ExecuterIDErrorPosts,
          ].filter(v=>{
            if (idx.indexOf(v.ReqID)==-1) {
              idx.push(v.ReqID)
              return true
            }
            return false

          }) ,
        fetched: false,
      });
      return

    }
    errors.forEach(err => {
      const {message} = err;
      if (message === "StatusUnauthorized"){
        this.setState({status: 401})
      }
    })

  };

  handleBadge = name => async e => {
    // console.log(name);
    if (name === "Пусто"){
      name = ""
    }

    await this.setState({
      vars:{
        ...this.state.vars,
        Status: name,
      }
    })
    this.fetch();
  }

  handle = order =>  async e => {

    if (this.state.order === order){
      order = "-" + order
    }

    await this.setState({
      fetched: true,
      order,
      vars:{
        ...this.state.vars,
        Order: OrderName(order),
      }
    })
    this.fetch()
  }
  renderTable = () => {

    return !this.state.fetched
    ? <Table order={this.state.order} items={this.state.items} handle={this.handle} />
    : (<div className="text-center"> <Loader
          type="Watch"
          color="#00BFFF"
          height="100"
          width="100"
        /></div>)
  }

    render() {
    if (this.state.status && this.state.status !== 200 ){
      const to = `/${this.state.status}`;
      return (<Redirect to={to} />)
    }

    return (<Store.Consumer>
      {State =>(<div>
        <Head location={this.props.location}/>
        {this.renderTable()}

        </div>)
      }
    </Store.Consumer>);
  }
}



export default Requests;
