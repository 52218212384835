export const Remove = `mutation($Customer:String!, $ReqID:String!)
{
  Remove(Customer:$Customer, ReqID:$ReqID )
}`;

export default `query($Limit:Int!){
  items: blocks(Limit:$Limit, State:1 Retry:9 Order:"-_id") {
    ID
    Income{From To PID}
    ReqID
    Customer
    Hash
    CreatedOn
    State
    Retry
    SC {
      External {
        ID
        Status
        Number
        Response
      }
    }
  }
}`;


