import React from 'react';
export const Store = React.createContext();

export const State = {
  auth: {
    username:'',
    password:'',
  },

}

State.auth.onSubmit = async function(e){
  e.preventDefault();
  const body = JSON.stringify({ query: `{hello}`, variables:{}});
  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  };
  const resp = await fetch('/graphql', options);
  const { data, errors } = await resp.json();
  if (errors)
    console.log(errors);

  if (data.hello === "Hello, world!"){
    window.location.replace("/#/requests")
  }
};


export default { Store, State };
