import React from 'react';
import { Link } from "react-router-dom";
import { FaBeer, FaTrash } from 'react-icons/fa';
import Fetch from '../../pkg/fetch';
import {Remove} from './query';
// import PropTypes from 'prop-types';

const options = {
  // era: 'long',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  // weekday: 'long',
  // timezone: 'UTC',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};

const style1 = {
   whiteSpace: 'nowrap',
   width: "12em"
};

class Thead extends React.Component {
  state={ loading: false }
  handle = async e => {
    this.setState({ loading: true })
    const {ReqID, Customer} = this.props.first;

    await Fetch(Remove, {ReqID, Customer});
    this.setState({ loading: false })
  }
  // <a target="_blank" rel="noopener noreferrer" className="btn btn-secondary" href={`https://call.servicechain.ru/ov/${props.id}`}><FaBeer /></ a>
  render() {
    const { props } = this;

    return (
      <thead>
        <tr>
          <td colSpan="6">
            <div className="btn-group" role="group" aria-label="Basic example">

              { !this.state.loading && <button className="btn btn-warning" onClick={this.handle}><FaTrash /></ button>}
            </div>
          </td>
        </tr>
          <tr>
            <th>From</th>
            <th>ReqID({props.l})</th>
            <th>ExtStatus</th>
            <th>Number</th>
            <th>Response</th>
            <th style={style1} >Date</th>
            <th>Hash</th>
          </tr>
      </thead>
    );
  }
}

const Status = (sc, i) => {
  if (i === 0){ return }
  // console.log(sc);

  if (!sc.External.Number) {
    return "table-danger"
  }
  if (sc.External.Status === 'Выполнено') {
    return "table-success"
  }
  if (sc.External.Status === 'Отклонено') {
    return "table-warning"
  }
}

const UnitToDate = stamp => new Date(stamp * 1000).toLocaleString("ru", options)

const Row = (props, i)=>(<tr key={`${props.ID}`} className={Status(props.SC, i)}>
  <td>{props.Income.From}</td>
  <td><Link to={`/block/${props.ID}`}> {props.ReqID}</Link></td>
  <td>{props.SC.External.Status}</td>
  <td>{props.SC.External.Number}</td>
  <td>{props.SC.External.Response}</td>
  <td>{UnitToDate(props.CreatedOn)}</td>
  <td>{props.Retry}({props.State})</td>
  <td>{props.Hash}</td>
</tr>);

const Table = props => (<table  className="table table-striped table-bordered">
  <Thead id={props.id} l={props.items.length} first={props.items[0]}/>
  <tbody>
    {props.items.map(Row)}
  </tbody>
</table>);

export default Table;
