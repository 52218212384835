import React, { Component } from 'react';
// import PropTypes from 'prop-types';
//
import Head from '../../pkg/head/';
import Loader from 'react-loader-spinner'

import Table from './table';
import Fetch from '../../pkg/fetch';
import query from './query';

class Contract extends Component {
	constructor(props) {
		// console.log(props);
		super(props);
		this.state = {
			id: props.match.params.reqid,
			items: [],
		};

		this.fetch();
	}
	fetch = async () => {
		const {data, errors} = await Fetch(query, {ReqID: this.state.id});
		if (!errors) {
			this.setState(data)
		}
		// console.log(this.state);
	};
	// render() {
	//	return this.state.items.length ? <Table {...this.state} /> : <h1>Loading...</ h1>;
	// }
	render() {
		return this.state.items.length
		?(<div>
			<Head location={this.props.location}/>
			<Table {...this.state} />
			</div>)
		: (<div className="text-center"> <Loader
         	type="Watch"
         	color="#00BFFF"
         	height="100"
         	width="100"
      	/></div>) ;
	}

}

export default Contract;
