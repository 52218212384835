import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Fetch from '../../pkg/fetch';
import query from './query';
import Head from '../../pkg/head/';

class Block extends Component {
	constructor(props) {
		// console.log(props);
		super(props);
		this.state = {
			hash: props.match.params.hash,
			block: false,
		};
		this.fetch();
	}
	fetch = async () => {
		const {data, errors} = await Fetch(query, {Hash: this.state.hash});
		if (!errors) {
			this.setState(data)
		}
		// console.log(this.state);
	};
	render() {
		return this.state.block
		? <div>
			<Head location={this.props.location}/>
			<pre>{JSON.stringify(this.state.block, false, "  ")}</pre>
			</div>
		: <h1>Loading...</ h1>;
	}
}

export default Block;
