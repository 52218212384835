import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import Fetch from '../../pkg/fetch';
import query from './query';
import Table from './table';
import Loader from 'react-loader-spinner'
import {OrderName} from "../helper.js"
import {  Link } from "react-router-dom";

// const Serialize = item => {
// 	return {
// 		ID: item.ReqID,
// 		Status: item.Status,
// 		Number: item.Number,
// 		Response: item.Response,
// 		// CreatedOn: new Date(item.CreatedOn*1000),
// 		CreatedOn: item.CreatedOn,
// 		// UpdatedOn: new Date(item.UpdatedOn*1000),
// 		UpdatedOn: item.UpdatedOn,
// 		Customer: item.Customer,
// 	}
// };

const getFrom = () => {
	let d = new Date()
	d.setDate(1)
	d.setMonth(d.getMonth()-1)
	d.setHours(0)
	d.setMinutes(0)
	return d
}

const getTo = () => {
	let d = new Date()
	d.setDate(1)
	d.setMonth(d.getMonth())
	d.setHours(0)
	d.setMinutes(0)
	return d
}

class Report extends React.Component {
	state = {
		Limit: 10000,
		executer: "unit",
		from: getFrom(),
		to: getTo(),
	}
	handleChange = (e)=>{
		console.log(e)
	}

	onChange = name => value => this.setState({ [name]:value })
	handleChange = e => {

		const {name, value} = e.target;
		this.setState({ [name]:value, items:[] })
	}
	fetch = async (e) => {
		e.preventDefault()
		this.setState({fetched: true});
		const {data, errors, status} = await Fetch(query, {...this.state,
			from: Math.ceil(this.state.from.getTime()/1000,0),
			to: Math.ceil(this.state.to.getTime()/1000,0),
		});
		if (!errors) {
			const items = data.items
			await this.setState({
				status,
				...data,
				len: data.items.length,
				items: items,
				fetched: false,
			});
			return
		}
		errors.forEach(err => {
			const {message} = err;
			if (message === "StatusUnauthorized"){
				this.setState({status: 401})
			}
		})
	}

	handle = order =>  async e => {
		if (this.state.order === order){
			order = "-" + order
		}
		await this.setState({
			fetched: true,
			order,
			vars:{
				...this.state.vars,
				Order: OrderName(order),
			}
		})
		this.fetch(e)
	}

	renderTable = () => {
		return !this.state.fetched
		? <Table order={this.state.order} items={this.state.items} handle={this.handle} />
		: (<div className="text-center"> <Loader
         	type="Watch"
         	color="#00BFFF"
         	height={100}
         	width={100}
      	/></div>)
	}

	render(){
		return (
			<div>
				<Link className="navbar-brand" to="/requests">
			    <img src="https://servicechain.ru/assets/img/logo_hover.svg" />&nbsp;
			    <span>SERVICECHAIN</span>
			  </Link>
				{this.state.items && (<h3>Итого: {this.state.len}</h3>)}
				<form onSubmit={this.fetch} >
					<div className="row">
						<div className="col-lg-2">
							<div className="form-group">
						    <label >Executer</label>
						    <input
						    	autoComplete="off"
						    	onChange={this.handleChange} name="executer" defaultValue={this.state.executer}
						    	type="text" className="form-control" placeholder="To"/>
						  </div>
						</div>
						<div className="col-lg-2">
							<div className="form-group">
						    <label >From</label>
						    <DateTimePicker className="form-control"
									onChange={this.onChange("from")}
									value={this.state.from}
						    />
						  </div>
						</div>

						<div className="col-lg-2">
							<div className="form-group">
						    <label >To</label>

						    <DateTimePicker className="form-control"
									onChange={this.onChange("to")}
									value={this.state.to}
						    />
						  </div>
						</div>
						<div className="col-lg-2">
							<div className="form-group">
						    <label >Limit</label>
						    <input
						    	autoComplete="off"
						    	onChange={this.handleChange}
						    	name="limit"
						    	defaultValue={this.state.Limit}
						    	type="text" className="form-control" placeholder="Limit"/>
						  </div>
						</div>

						<div className="col-lg-2 text-center">
							<br/>
							<button className="btn btn-primary" >Submit</button>
						</div>

					</div>
				</form>
				{this.renderTable()}
			</div>
		);
	}
}


export default Report;
