export const statuses = [
  ["Unknown",""],
  ["Adopt","Принят"],
  ["Done", "Выполнено"],
  ["Cancel", "Отклонено"],

  // ["Reg","Зарегистрирована"],
  // ["CloseRemote","Закрыта удалённо"],
  // ["Worked","Устройство работает"],
  // ["Cancel","Отклонено"],
  // ["Close","Закрыта"],
]

const getExecuterStatuses = statuses.map(v=>{
  return `Executer${v[0]}: Count(From: $From, Executer: $Executer,  Status: "${v[1]}")`
}).join("\n")

const getAllStatuses = statuses.map(v=>{
  return `All${v[0]}: Count(Status: "${v[1]}")`
}).join("\n")

export default `query($Executer: String, $Order: String, $Limit: Int, $Status: String, $From: Int){
  items: Requests (Executer: $Executer, Order: $Order, Limit: $Limit, Status:$Status, From: $From){
  ReqID
  ReqNumber
  Customer
  Executer
  Status
  Response
  Number
  CreatedOn
  UpdatedOn
  }
  Executer: Count(From: $From, Executer: $Executer)
  ${getExecuterStatuses}
  All: Count()
  ${getAllStatuses}

}`;

