export default `query($executer: String, $from: Int, $to: Int, $Limit: Int){
    items: Requests(
        Executer: $executer
        From: $from
        To: $to
        Limit: $Limit
    ) {
        ReqID
        ReqNumber
        Customer
        Executer
        Status
        Response
        Number
        CreatedOn
        UpdatedOn
    }
}`
