export const statuses = [
  ["Unknown",""],
  ["Adopt","Принят"],
  ["Done", "Выполнено"],
  ["Cancel", "Отклонено"],

  // ["Reg","Зарегистрирована"],
  // ["CloseRemote","Закрыта удалённо"],
  // ["Worked","Устройство работает"],
  // ["Cancel","Отклонено"],
  // ["Close","Закрыта"],
]

const getCustomerStatuses = statuses.map(v=>{
  return `Customer${v[0]}: Count(From: $From, Customer: $Customer,  Executer:$Executer Status: "${v[1]}")`
}).join("\n")

const getAllStatuses = statuses.map(v=>{
  return `All${v[0]}: Count(Status: "${v[1]}")`
}).join("\n")

export default `query($Executer: String, $Customer: String, $Order: String, $Limit: Int, $Status: String, $From: Int){
  items: Requests(Executer: $Executer, Customer: $Customer, Order: $Order, Limit: $Limit, Status: $Status, From: $From) {
    ReqID
    ReqNumber
    Customer
    Executer
    Status
    Response
    Number
    CreatedOn
    UpdatedOn
  }
  Customer: Count(From: $From, Customer: $Customer)
  ${getCustomerStatuses}
  All: Count()
  ${getAllStatuses}

}`;

export const old = `mutation {
  items: Contracts(Customer: "letual") {
    ReqID
    Customer
    Last {
      ReqID
      Customer
      Hash
      CreatedOn
      SC{
        External {
          ID
          Status
          Number
          Response
        }
        ReqStatus
      }
    }
  }
}`;
