

export const OrderName = name =>{
	const res = {

		ID:"ReqID",
		"-ID":"-ReqID",

	}[name]
	if (res === undefined){
		return name
	}
	return res
}
export default {
	OrderName,
};
