import React, { Component } from 'react';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
// import PropTypes from 'prop-types';
import Fetch from '../../pkg/fetch';
import query, { UpdateQuery } from './query';
import Head from '../../pkg/head/';

class Block extends Component {
	constructor(props) {
		// console.log(props);
		super(props);
		this.state = {
			id: props.match.params.id,
			block: false,
		};
		this.fetch();
	}
	fetch = async () => {
		const {data, errors} = await Fetch(query, {ID: this.state.id});
		if (!errors) {
			this.setState(data)
		}
		// console.log(this.state);
  };


  ReSend = async (e) => {
    const result = window.confirm(`Resend Post ${this.state.id}?`);
    if (result){
      await this.Update({
        Lock: false,
        Retry: 1,
        State: 0
      })
      this.fetch()
    }
  }

  ResetErrCounter = async (e) => {
    const result = window.confirm(`Reset error send counter ${this.state.id}?`);
    if (result) {
      await this.Update({
        Lock: false,
        Retry: 2,
        State: 1
      })
      this.fetch()
    }
  }

  Update = async q => {
    const { data, errors = false } = await Fetch(UpdateQuery, {
      ID: this.state.id,
      ...q
    });
    if (errors) {
      console.log('Reset', errors);
    }
  }


	render() {
		return this.state.block
		? <div>
			<Head location={this.props.location}/>
        <button className="btn btn-danger" onClick={this.ReSend}>ReSend</button>
        <button className="btn btn-warning" onClick={this.ResetErrCounter}>Reset Retry Counter</button>
      <SyntaxHighlighter language="javascript" style={tomorrow}>
        {JSON.stringify(this.state.block, false, "  ")}
      </SyntaxHighlighter>
			</div>
		: <h1>Loading...</ h1>;
	}
}

export default Block;
