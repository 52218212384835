import React from 'react';

const Modal = props =>{
  const style = {
    paddingRight: 15,
    display: 'block'
  };
  return <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style={style}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLiveLabel">
              {props.title}
            </h5>
            <button type="button" onClick={props.close}  className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {props.children}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={props.done} className="btn btn-primary">
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>;
};

export default Modal;
