import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {OrderName} from "../helper.js"
// import PropTypes from 'prop-types';
//
import Head from '../../pkg/head/';
import Table from '../../pkg/table';
import Fetch from '../../pkg/fetch';
import query from './query';
import Loader from 'react-loader-spinner'
import Filter from '../../pkg/filter';
import Panel from '../../pkg/panel';
// import DonutChart from '../../pkg/donut/';
// import PieChart from 'react-minimal-pie-chart';


const Serialize = item => {
	const CreatedOn = new Date(item.CreatedOn*1000);
	const UpdatedOn = new Date(item.UpdatedOn*1000);

	// console.log(d.toLocaleString("ru", options));
	return {
		ID: item.ReqID,
		Status: item.Status,
		Number: item.Number,
		Response: item.Response,
		CreatedOn,
		UpdatedOn,
		Customer: item.Customer,
	}
};

class Contracts extends Component {
	constructor(props) {
		super(props);
		const { year="2018", month="01", day="01" } = props.match.params;
		this.state = {
			vars:{
				From: new Date( Number(year), Number(month)-1, Number(day)).getTime() / 1000,
				year,
				month,
				day,
				Customer: "letual",
				Order: '-UpdatedOn',
				// Status:'!Выполнено',
				Limit: 100,
				Skip:0,
			},
			fetched: true,
			order: 'ReqID',
			items: [],
			statuses: ['Выполнено', 'Принят', 'Отклонено']
		};
		setInterval(()=>{
			console.log("Data refresh.");
			this.fetch()
		}, 5 * 60 * 1000)
	}
	componentDidMount() {
		this.fetch();
  	}

	handelFilter = async e => {
		e.preventDefault();
		if (e.keyCode === 13) {
			this.fetch();
			return
		}
		const {year, month, day} = this.state.vars
		const {name, value, type} = e.target;
		const state = {};

		state[name]= type === "number"
		? Number(value)
		: value;

		await this.setState({vars:{...this.state.vars, ...state}});
		if (name in ["year", "month"]) {
			state.From = new Date( Number(year), Number(month)-1, Number(day)).getTime() / 1000;
		}
	}

	donut= (data) => {
		// const colors = {
		// 	"Выполнено": "#B8E2C0",
		// 	'Отклонено':'#F2B8BF',
		// 	'Принят':'#EFEFEF',
		// }
		// const sum = data.reduce((r,v) => (Number(v.Value) + r), 0)

		// return data.map(({Name,Value}) => ({
		// 	title: Name,
		// 	color: colors[Name],
		// 	Value,
		// 	value: parseInt(Value / sum * 100),
		// }))
	}

	fetch = async () => {
		this.setState({fetched:true});
		const {data, errors, status} = await Fetch(query, this.state.vars);
		if (!errors) {
			// const counts = this.donut(data.Counts)
			// const all = this.donut(data.All)
			this.setState({
				status,
				...data,
				// counts,
				// sum: counts.reduce((r,v) => (Number(v.Value) + r), 0),
				// all,
				// allSum: all.reduce((r,v) => (Number(v.Value) + r), 0),
				items: data.items,
				fetched: false,
			});
			return

		}
		errors.forEach(err => {
			const {message} = err;
			if (message === "StatusUnauthorized"){
				this.setState({status: 401})
			}
		})


	};

	handleBadge = name => async e => {
		// console.log(name);
		await this.setState({
			vars:{
				...this.state.vars,
				Status: name,
			}
		})
		this.fetch();
	}

	handle = order =>  async e => {

		if (this.state.order === order){
			order = "-" + order
		}

		await this.setState({
			fetched: true,
			order,
			vars:{
				...this.state.vars,
				Order: OrderName(order),
			}
		})
		this.fetch()
	}
	renderTable = () => {
		return !this.state.fetched
		? <Table order={this.state.order} items={this.state.items} handle={this.handle} />
		: (<div className="text-center"> <Loader
         	type="Watch"
         	color="#00BFFF"
         	height={100}
         	width={100}
      	/></div>)
	}

		render() {
		// console.log(this.state.data);
		const style = {
			marginRight:4,
		}
		const style1 ={
			float: 'left',
		}
		if (this.state.status && this.state.status !== 200 ){
			const to = `/${this.state.status}`;
			return (<Redirect to={to} />)
		}

		return (<div>
			<Head location={this.props.location}/>
			<div className="row">
				<div className="col-lg-6">
					<Panel name="Customer" {...this.state}  handle={this.handleBadge}/>
				</div>
				<div className="col-lg-6">
						<Filter {...{...this.state.vars, handle: this.handelFilter }}/>&nbsp;
				</div>
			</div>
			{this.renderTable()}
			<Panel name="All" {...this.state}  handle={this.handleBadge}/>

		</div>);
	}
}

export default Contracts;
